<template>
  <div>
    <b-button-group :vertical="vertical" class="col-12 p-0">
      <b-button :size="size" v-for="(value, index) in options" :key="index" 
      :variant="variant"
      :pressed="optionButtons[index]"
      @click="valueOptions(value,index)"
      >{{ value.text }}
      </b-button>
    </b-button-group>
 </div>
</template>
    
<script>
import { BButton, BButtonGroup } from "bootstrap-vue";

export default {
  name: "OptionsFilters",
  components: {
    BButton,
    BButtonGroup,
  },
  data() {
    return {
      optionButtons: []
    };
  },
  created() {
    this.buttonsLength()
  },
  methods: {
    valueOptions(value,index) {
      this.$emit('optionsFilter', value, this.name_prop);
      this.optionButtons = this.optionButtons.map((item) => {
        item = false 
        return item
      })
      this.optionButtons[index] = true
    },
    buttonsLength() {
      for (let i = 0; i < this.options.length; i++) {
        this.optionButtons.push(false)
      }
    }
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    variant: {
      type: String,
      default: "outline-secondary",
    },
    size: {
      type: String,
      default: "sm",
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    name_prop: {
      type: String,
    }
  },
};
</script>
<style>
</style>